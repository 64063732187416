export const ROUTES = {
  HOME: "/",
  FINDMEMBER: "/find-member",
  ADDMEMBER: "/add-member",
  EDITMEMBER: "/edit-member/:id",
  INVENTORY: "/inventory",
  PRODUCTS: "/products",
  NEWPRODUCT: "/products/new",
  EDITPRODUCT: "/edit-product/:id",
  POS: "/point-of-sale",
  VISIT: "/visit-history",
  PAYMENTS: "/payments",
  SETTINGS: "/settings",
  LOGIN: "/login",
  USERPROFILE: "/user-profile",
  UPDATEPASSW0RD: "/update-password",
};
